<template>
  <b-container fluid>
    <bo-page-title />
    <section id="invrelate">
      <b-tabs v-if="isList">
        <b-tab title="Page Settings">
          <bo-card title="Hero Image">
            <b-row>
              <b-col md="4">
                <bo-card-img title="Investor Relation" :src="uploader(heroImage.ssf_file, '/assets/images/no-slide.jpg')">
                  <template #buttons>
                    <b-button
                      variant="secondary"
                      size="sm"
                      pill
                      class="btn-icon"
                      v-b-tooltip.hover="'Update'"
                      @click="changeHero()"
                      v-if="moduleRole('updateHero')"
                    >
                      <i class="fas fa-pencil-alt" />
                    </b-button>
                  </template>
                </bo-card-img>
              </b-col>
            </b-row>

            <b-modal 
              v-model="heroEditorOpen"
              :title="'Update Hero Image '+ pageTitle"
              size="lg"
              hide-footer
              body-class="p-0"
            >
              <validation-observer
                v-slot="{ handleSubmit }" ref="VFormHero"
              >
                
                <b-form @submit.prevent="handleSubmit(submitHero)">
                  <b-card-body>
                    <b-row>
                      <b-col md="6">
                        <div class="file-uploader">
                          <label>Hero Image Desktop: </label>
                          <Uploader v-model="heroImage.ssf_file" type="hero-product"/>
                          <VValidate 
                            name="Hero Image Desktop" 
                            v-model="heroImage.ssf_file"
                            :rules="validationHeroImage.ssf_file" 
                          />
                        </div>
                        
                        <div class="file-uploader">
                          <label>Hero Image Mobile: </label>
                          <Uploader v-model="heroImage.ssf_file_mobile" type="hero-product-mobile" use-preview />
                          <VValidate 
                            name="Hero Image Mobile" 
                            v-model="heroImage.ssf_file_mobile"
                            :rules="validationHeroImage.ssf_file_mobile" 
                          />
                        </div>
                      </b-col>
                      <b-col md="6">
                        <b-card class="border mb-0">
                          <template #header>
                            <h5 class="card-title">Content (ID)</h5>
                          </template>
                          <b-form-group label-for="heroTitleId">
                            <template #label>Title <strong class="text-danger">*</strong></template>
                            <b-form-input id="heroTitleId" v-model="heroImage.ssf_title_id" @input="autoFill('ssf_alt_mobile_id','ssf_alt_id','ssf_title_id')" @keyup="removeWildChar" @keydown="removeWildChar" />
                            <VValidate 
                              name="Content ID" 
                              v-model="heroImage.ssf_title_id"
                              :rules="{...validationHeroImage.ssf_title_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>

                          <b-form-group label-for="altId">
                            <template #label>Alt Image <strong class="text-danger">*</strong></template>
                            <b-form-input id="altId" v-model="heroImage.ssf_alt_id" @keyup="removeWildChar" @keydown="removeWildChar" />
                            <VValidate 
                              name="Alt Image Id" 
                              v-model="heroImage.ssf_alt_id"
                              :rules="{...validationHeroImage.ssf_alt_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>
                          
                          <b-form-group label-for="altIdM">
                            <template #label>Alt Image Mobile<strong class="text-danger">*</strong></template>
                            <b-form-input id="altIdM" v-model="heroImage.ssf_alt_mobile_id" @keyup="removeWildChar" @keydown="removeWildChar" />
                            <VValidate 
                              name="Alt Image Mobile ID" 
                              v-model="heroImage.ssf_alt_mobile_id"
                              :rules="{...validationHeroImage.ssf_alt_mobile_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>
                          
                        </b-card>
                        <b-card class="border mb-0">
                          <template #header>
                            <h5 class="card-title">Content (EN)</h5>
                          </template>
                          <b-form-group label-for="heroTitleEn">
                            <template #label>Title <strong class="text-danger">*</strong></template>
                            <b-form-input id="heroTitleEn" @input="autoFill('ssf_alt_mobile_en','ssf_alt_en','ssf_title_en')" v-model="heroImage.ssf_title_en" @keyup="removeWildChar" @keydown="removeWildChar" />
                            <VValidate 
                              name="Content EN" 
                              v-model="heroImage.ssf_title_en"
                              :rules="{...validationHeroImage.ssf_title_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>

                          <b-form-group label-for="altEN">                          
                            <template #label>Alt Image <strong class="text-danger">*</strong></template>
                            <b-form-input id="altEN" v-model="heroImage.ssf_alt_en" @keyup="removeWildChar" @keydown="removeWildChar" />
                            <VValidate 
                              name="Alt Image En" 
                              v-model="heroImage.ssf_alt_en"
                              :rules="{...validationHeroImage.ssf_alt_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>
                          
                          <b-form-group label-for="altEnM">
                            <template #label>Alt Image Mobile<strong class="text-danger">*</strong></template>
                            <b-form-input id="altIEn" v-model="heroImage.ssf_alt_mobile_en" @keyup="removeWildChar" @keydown="removeWildChar" />
                            <VValidate 
                              name="Alt Image Mobile EN" 
                              v-model="heroImage.ssf_alt_mobile_en"
                              :rules="{...validationHeroImage.ssf_alt_mobile_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-card-body>
                  <b-card-footer>
                    <div class="text-right">
                      <b-button type="submit" variant="info" class="btn-rounded">Save Changes</b-button>
                    </div>
                  </b-card-footer>
                </b-form>
              </validation-observer>
            </b-modal>
          </bo-card>
          <validation-observer
            v-slot="{ handleSubmit }" ref="VFormContent"
            >
          <b-form @submit.prevent="handleSubmit(submitPrologue)" v-if="moduleRole('updateContent')">     
            <b-card no-body>
                <template #header>
                <h5 class="card-title">Prologue Content</h5>
                </template>
                
                <b-card-body>
                    <b-row>
                    <b-col md="6">
                        <b-form-group label-for="infoClaimDesc_id">
                        <template #label>Prologue Content (ID)<strong class="text-danger">*</strong></template>
                            <b-form-textarea rows="5" id="infoClaimDesc_id" placeholder="Prologue Content Id" v-model="prologueContent.ssc_desc_id" @keyup="removeWildChar" @keydown="removeWildChar" />
                            <VValidate 
                                name="Description Id" 
                                v-model="prologueContent.ssc_desc_id"
                                :rules="{...prologueContentValidation.ssc_desc_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group label-for="infoClaimDesc_en">
                        <template #label>Prologue Content (EN)<strong class="text-danger">*</strong></template>
                            <b-form-textarea rows="5" id="infoClaimDesc_en" placeholder="Prologue Content EN" v-model="prologueContent.ssc_desc_en" @keyup="removeWildChar" @keydown="removeWildChar" />
                            <VValidate 
                                name="Description En" 
                                v-model="prologueContent.ssc_desc_en"
                                :rules="{...prologueContentValidation.ssc_desc_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                        </b-form-group>
                    </b-col>
                    </b-row>
                </b-card-body>
                <b-card-footer>
                    <div class="text-right">
                        <b-button class="btn-rounded" type="submit" variant="info">Save Changes</b-button>
                    </div>
                </b-card-footer>
            </b-card>
          </b-form>
        </validation-observer>
        </b-tab>
        <b-tab title="SEO Settings" v-if="moduleRole('updateSEO')">
          <b-card no-body>
            <b-card-header>
              <b-card-title title-tag="h5">Investor Relation SEO Settings</b-card-title>
            </b-card-header>
            <validation-observer
              v-slot="{ handleSubmit }" ref="VFormSEO"
            >
            <b-form @submit.prevent="handleSubmit(submitSEO)">
              <b-card-body>
                <b-row>
                  <b-col lg="6">
                    <b-form-group label-for="homeMetaTitle">
                      <template #label>
                        Meta Title (ID)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami judul dari halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Title adalah 60 karakter.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="seoPageSetting.sss_meta_title_id" />
                      <VValidate 
                        name="Meta Title ID" 
                        v-model="seoPageSetting.sss_meta_title_id"
                        :rules="{...seoValidation.sss_meta_title_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      />   
                    </b-form-group>
                  </b-col>

                  <b-col lg="6">
                    <b-form-group label-for="homeMetaTitle">
                      <template #label>
                        Meta Title (EN)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="seoPageSetting.sss_meta_title_en" />
                      <VValidate 
                        name="Meta Title EN" 
                        v-model="seoPageSetting.sss_meta_title_en"
                        :rules="{...seoValidation.sss_meta_title_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      />   
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col lg="6">
                    <b-form-group label-for="homeMetaDesc">
                      <template #label>
                        Meta Description (ID)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'Isi kolom ini sebagai deskripsi singkat dari isi halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Description adalah 120-155 karakter.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-textarea id="homeMetaDesc" rows="5" placeholder="Meta Description" v-model="seoPageSetting.sss_meta_description_id" />
                      <VValidate 
                        name="Meta Description ID" 
                        v-model="seoPageSetting.sss_meta_description_id"
                        :rules="{...seoValidation.sss_meta_description_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      /> 
                    </b-form-group>
                  </b-col>

                  <b-col lg="6">
                    <b-form-group label-for="homeMetaDesc">
                      <template #label>
                        Meta Description (EN)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'This field acts as the summary for your website\'s page. A good practice is to keep it around 120-155 characters.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-textarea id="homeMetaDesc" rows="5" placeholder="Meta Description" v-model="seoPageSetting.sss_meta_description_en" />
                      <VValidate 
                        name="Meta Description EN" 
                        v-model="seoPageSetting.sss_meta_description_en"
                        :rules="{...seoValidation.sss_meta_description_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      /> 
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col lg="6">
                    <b-form-group label-for="homeMetaTags">
                      <template #label>
                        Meta Keywords (ID)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami topik halaman website. Gunakan keyword yang relevan dan user friendly, maksimal 10 keyword.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-tags id="homeMetaTags" v-model="seoPageSetting.sss_meta_keyword_id" placeholder="Type and press enter ..." remove-on-delete :tag-validator="validatorTags"
                        tag-variant="primary" tag-class="text-white"/>
                    </b-form-group>
                  </b-col>

                  <b-col lg="6">
                    <b-form-group label-for="homeMetaTags">
                      <template #label>
                        Meta Keywords (EN)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'This field tell Search Engines what the topic of a page is. Use a relevant and short topic, and make sure to keep only up to 10 keywords.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-tags id="homeMetaTags" v-model="seoPageSetting.sss_meta_keyword_en" placeholder="Type and press enter ..." remove-on-delete :tag-validator="validatorTags"
                        tag-variant="primary" tag-class="text-white"/>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
              <b-card-footer>
                <div class="text-right">
                  <b-button class="btn-rounded" type="submit" variant="primary">Save Changes</b-button>
                </div>
              </b-card-footer>
            </b-form>
            </validation-observer>
          </b-card>
        </b-tab>
      </b-tabs>
    </section>
  </b-container>
</template>
<script>
import GlobalVue from '@/libs/Global.vue'
import BoCardImg from '@/components/backend/BoCardImg.vue'

let $ = global.jQuery
let _ = global._

export default {
  extends: GlobalVue,
  components: {
    BoCardImg
  },
  data() {
    return {
      mrValidation: {},
      itemsToolbar: [
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'undo',
        'redo'
      ],
      heroEditorOpen: false,
      heroImage: {},
      validationHeroImage: {},
      seoPageSetting: {},
      seoValidation: {},
      prologueContent: {},
      prologueContentValidation: {}
    }
  },
  mounted(){
    this.apiGet()
  },
  methods: {
    autoFill(to = "", to2 = "", from = ""){
      this.heroImage[to] = this.heroImage[from]
      this.heroImage[to2] = this.heroImage[from]    
    },
    changeHero(){
      this.heroEditorOpen = !this.heroEditorOpen
    },
    submitPrologue(){
        this.doSubmit(
        "/do/" + this.modulePage,
        _.assign({
          type: 'updatePrologue'
        }, this.prologueContent),
        (type, resp) => {
          if (type == 'success') {
            if(Object.keys(resp.data.rowAlgoliaID).length){
              this.submitStaicContentAlgolia('inv-relation',resp.data.rowAlgoliaID,'id')
            }
            if(Object.keys(resp.data.rowAlgoliaEN).length){
              this.submitStaicContentAlgolia('inv-relation',resp.data.rowAlgoliaEN,'en')
            }
            this.loadingOverlay = true
            this.apiGet()
            window.scrollTo(0, 0)
          }else{
            if(resp.response.status==422) {
              if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                var msg = ""
                $.each(Object.keys(resp.response.data), (i, v) => {
                  msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v])
                })
                return this.$swal.fire("Blocked With Validation!", msg)
              } else {
                return this.$swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
              }
            } else if (resp.response.status == 400) {
              return this.$swal.fire({
                title: resp.response.data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
              })
            }
          }
        },
        'POST', 'VFormContent'
      )
    },
  },
  watch: {
    $route() {
      this.apiGet()
    },
    'seoPageSetting.sss_meta_title_id'(v){
      let el = document.getElementById('Meta Title ID')
      if(el){
        let showcount = document.getElementById('Meta Title ID' + 'count')
        let cErr = document.getElementById('Meta Title ID' + 'count-err')
        if(v.length >= 3 && v.length < 60){
          el.style.display = "none"
          showcount.innerHTML = 60 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"        
        }
      }
    },
    'seoPageSetting.sss_meta_title_en'(v){
      let el = document.getElementById('Meta Title EN')
      if(el){
        let showcount = document.getElementById('Meta Title EN' + 'count')
        let cErr = document.getElementById('Meta Title EN' + 'count-err')
        if(v.length >= 3 && v.length < 60){
          showcount.innerHTML = 60 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"          
          showcount.style.display = "none"
        }
      }
    },
    'seoPageSetting.sss_meta_description_id'(v){
      let el = document.getElementById('Meta Description ID')
      if(el){
        let showcount = document.getElementById('Meta Description ID' + 'count')
        let cErr = document.getElementById('Meta Description ID' + 'count-err')
        if(v.length >= 3 && v.length < 160){
          showcount.innerHTML = 160 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"
        }
      }
    },
    'seoPageSetting.sss_meta_description_en'(v){
      let el = document.getElementById('Meta Description EN')
      if(el){
        let showcount = document.getElementById('Meta Description EN' + 'count')
        let cErr = document.getElementById('Meta Description EN' + 'count-err')
        if(v.length >= 3 && v.length < 160){
          showcount.innerHTML = 160 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"
        }
      }
    },
    
  }
}
</script>